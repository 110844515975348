@import "./app/styles/icons";

/* You can add global styles to this file, and also import other style files */
html {
    font-size: 16px;
  }
  
  * {
    font-family: "Muli", sans-serif;
  
    -webkit-touch-callout: none;
    /* prevent callout to copy image, etc when tap to hold */
    -webkit-text-size-adjust: none;
    /* prevent webkit from resizing text to fit */
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    /* prevent tap highlight color / shadow */
    -webkit-tap-highlight-color: transparent;
    // -webkit-user-select: none;
    /* prevent copy paste, to allow, change 'none' to 'text' */
  }

  
body {
  margin: 0;
  padding: 0;
  height: fit-content;
  min-height: 100%;
  width: 100%;
  position: fixed;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 500px) {
    // position: relative;
    // top: 0;
}

  &::before,
  &::after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}