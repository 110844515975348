@charset "UTF-8";
@font-face {
  font-family: "edutin-icons";
  src: url("../../assets/fonts/edutin-icons.eot?qlstaq");
  src: url("../../assets/fonts/edutin-icons.eot?qlstaq#iefix") format("embedded-opentype"), url("../../assets/fonts/edutin-icons.ttf?qlstaq") format("truetype"), url("../../assets/fonts/edutin-icons.woff?qlstaq") format("woff"), url("../../assets/fonts/edutin-icons.svg?qlstaq#edutin-icons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "edutin-icons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-activity:before {
  content: "\e941";
}

.icon-add-images:before {
  content: "\e97f";
}

.icon-add-text:before {
  content: "\e91b";
}

.icon-add-user:before {
  content: "\e9b5";
}

.icon-alarms:before {
  content: "\e92e";
}

.icon-alert:before {
  content: "\e93f";
}

.icon-answers:before {
  content: "\e91e";
}

.icon-apuntes:before {
  content: "\e92f";
}

.icon-arrow-down:before {
  content: "\e924";
}

.icon-arrow-left:before {
  content: "\e925";
}

.icon-arrow-point-to:before {
  content: "\e95f";
}

.icon-arrow-right:before {
  content: "\e934";
}

.icon-arrow-strong-down:before {
  content: "\e920";
}

.icon-arrow-strong-left:before {
  content: "\e922";
}

.icon-arrow-strong-right:before {
  content: "\e923";
}

.icon-arrow-strong-up:before {
  content: "\e921";
}

.icon-arrow-to-right:before {
  content: "\e974";
}

.icon-arrow-to-up:before {
  content: "\e976";
}

.icon-arrow-up:before {
  content: "\e938";
}

.icon-arrow-up-to:before {
  content: "\e9b8";
}

.icon-arrow_down_alt:before {
  content: "\e9d4";
}

.icon-arrow_right_alt:before {
  content: "\e95b";
}

.icon-arrow_sort:before {
  content: "\e9d5";
}

.icon-arrow_up_alt:before {
  content: "\e9d6";
}

.icon-attached:before {
  content: "\e90c";
}

.icon-back:before {
  content: "\e94b";
}

.icon-back-arrow:before {
  content: "\e911";
}

.icon-bell-filled:before {
  content: "\e9cc";
}

.icon-bell-outline:before {
  content: "\e9cb";
}

.icon-bold:before {
  content: "\e935";
}

.icon-broadcast:before {
  content: "\e988";
}

.icon-bullet:before {
  content: "\e971";
}

.icon-call:before {
  content: "\e98a";
}

.icon-call-us:before {
  content: "\e99e";
}

.icon-call-us-filled:before {
  content: "\e9a8";
}

.icon-cam:before {
  content: "\e979";
}

.icon-certificate:before {
  content: "\e964";
}

.icon-chat:before {
  content: "\e982";
}

.icon-circle-cross:before {
  content: "\e9d3";
}

.icon-circle-forward-arrow:before {
  content: "\e9ca";
}

.icon-circle-questions:before {
  content: "\e959";
}

.icon-circle-return-arrow:before {
  content: "\e9c9";
}

.icon-close:before {
  content: "\e965";
}

.icon-cloud-error:before {
  content: "\e910";
}

.icon-code:before {
  content: "\e90e";
}

.icon-code-outline:before {
  content: "\e90d";
}

.icon-code-strong:before {
  content: "\ea80";
}

.icon-comments:before {
  content: "\e92c";
}

.icon-community:before {
  content: "\e962";
}

.icon-contacts_follows:before {
  content: "\e97e";
}

.icon-content:before {
  content: "\e975";
}

.icon-contributed:before {
  content: "\e94f";
}

.icon-contributions:before {
  content: "\e94e";
}

.icon-copy:before {
  content: "\e96e";
}

.icon-copy-filled:before {
  content: "\e98d";
}

.icon-copy-outline:before {
  content: "🗆";
}

.icon-copy-outline-r:before {
  content: "\e9a7";
}

.icon-course-content:before {
  content: "\e94d";
}

.icon-cross:before {
  content: "\e939";
}

.icon-cross-bolder:before {
  content: "\e9b3";
}

.icon-cross-strong:before {
  content: "\e9aa";
}

.icon-cumulative-hours:before {
  content: "\e950";
}

.icon-curriculum:before {
  content: "\e9cd";
}

.icon-delete:before {
  content: "\e913";
}

.icon-delete-filled:before {
  content: "\e9c6";
}

.icon-delete-outline:before {
  content: "\e9c7";
}

.icon-document:before {
  content: "\e942";
}

.icon-double-arrow-left:before {
  content: "\e9ba";
}

.icon-double-arrow-right:before {
  content: "\e9bb";
}

.icon-download:before {
  content: "\e944";
}

.icon-download-bottom:before {
  content: "\e94a";
}

.icon-download-ouline:before {
  content: "\e9c8";
}

.icon-download-video:before {
  content: "\e949";
}

.icon-earth:before {
  content: "\e945";
}

.icon-earth-strong:before {
  content: "\e9ab";
}

.icon-edit:before {
  content: "\e96d";
}

.icon-edutin:before {
  content: "\e98e";
}

.icon-ellipsis-h:before {
  content: "\e91c";
}

.icon-ellipsis-v:before {
  content: "\e969";
}

.icon-email-outline:before {
  content: "\e9a0";
}

.icon-evaluacion-source:before {
  content: "\e947";
}

.icon-eye:before {
  content: "\e93e";
}

.icon-eye-strong:before {
  content: "\e9ad";
}

.icon-face-laugh:before {
  content: "\e985";
}

.icon-facebook:before {
  content: "\e905";
}

.icon-favorite-filled:before {
  content: "\e9c2";
}

.icon-favorite-outline:before {
  content: "\e9c3";
}

.icon-fbook:before {
  content: "\e96c";
}

.icon-feedback:before {
  content: "\e963";
}

.icon-file:before {
  content: "\e98b";
}

.icon-file-excel:before {
  content: "\eae2";
}

.icon-file-openoffice:before {
  content: "\eae0";
}

.icon-file-pdf:before {
  content: "\eadf";
}

.icon-file-text2:before {
  content: "\e991";
}

.icon-file-word:before {
  content: "\eae1";
}

.icon-file-zip:before {
  content: "\e992";
}

.icon-filter:before {
  content: "\e9ae";
}

.icon-filter-light:before {
  content: "\e97b";
}

.icon-folder:before {
  content: "\e98c";
}

.icon-follow:before {
  content: "\e951";
}

.icon-forum:before {
  content: "\e96f";
}

.icon-forward:before {
  content: "\e912";
}

.icon-fullscreen:before {
  content: "\e940";
}

.icon-fullscreen-exit:before {
  content: "\e94c";
}

.icon-github:before {
  content: "\e906";
}

.icon-google:before {
  content: "\e907";
}

.icon-h-line:before {
  content: "\e99a";
}

.icon-hangup:before {
  content: "\e986";
}

.icon-happy:before {
  content: "\e983";
}

.icon-happy2:before {
  content: "\e984";
}

.icon-hat:before {
  content: "\e9b0";
}

.icon-headphone:before {
  content: "\e903";
}

.icon-headphone-filled:before {
  content: "\e9a9";
}

.icon-home:before {
  content: "\e952";
}

.icon-id-card:before {
  content: "\e997";
}

.icon-image:before {
  content: "\e915";
}

.icon-info:before {
  content: "\e90f";
}

.icon-insta-outline:before {
  content: "\e9a1";
}

.icon-instagram:before {
  content: "\e9a2";
}

.icon-italic:before {
  content: "\e937";
}

.icon-laptop:before {
  content: "\e9b6";
}

.icon-laptop-square:before {
  content: "\e990";
}

.icon-libreoffice:before {
  content: "\eae3";
}

.icon-license:before {
  content: "\e993";
}

.icon-like:before {
  content: "\e914";
}

.icon-like-filled:before {
  content: "\e9c0";
}

.icon-like-outline:before {
  content: "\e9c1";
}

.icon-link:before {
  content: "\e918";
}

.icon-linkedin:before {
  content: "\e99d";
}

.icon-live:before {
  content: "\e989";
}

.icon-lock:before {
  content: "\e946";
}

.icon-lock-small:before {
  content: "\e987";
}

.icon-lock-strong:before {
  content: "\e9ac";
}

.icon-mail-filled:before {
  content: "\e931";
}

.icon-mail-outline:before {
  content: "\e996";
}

.icon-mail-outline1:before {
  content: "\e9b7";
}

.icon-medal .path1:before {
  content: "\e99b";
  color: rgb(29, 68, 141);
}

.icon-medal .path2:before {
  content: "\e99f";
  margin-left: -1em;
  color: rgb(255, 234, 128);
}

.icon-medal .path3:before {
  content: "\e9a3";
  margin-left: -1em;
  color: rgb(255, 204, 102);
}

.icon-medal-outline:before {
  content: "\e9c4";
}

.icon-message:before {
  content: "\e953";
}

.icon-messenger:before {
  content: "\e9a4";
}

.icon-mic:before {
  content: "\e97a";
}

.icon-mic-filled:before {
  content: "\e902";
}

.icon-mic1:before {
  content: "\e972";
}

.icon-minus:before {
  content: "\e930";
}

.icon-minus-strong:before {
  content: "\ea0b";
}

.icon-mssnger2:before {
  content: "\e9a5";
}

.icon-new-note:before {
  content: "\e93b";
}

.icon-new-window:before {
  content: "\e9c5";
}

.icon-next:before {
  content: "\e954";
}

.icon-notes:before {
  content: "\e955";
}

.icon-notification-bell:before {
  content: "\e97c";
}

.icon-pause:before {
  content: "\e956";
}

.icon-pause-circle:before {
  content: "\e93a";
}

.icon-pause1:before {
  content: "\e994";
}

.icon-pen-outline:before {
  content: "\e999";
}

.icon-pencil:before {
  content: "\e995";
}

.icon-photo-camera:before {
  content: "\e9ce";
}

.icon-play-arrow:before {
  content: "\e928";
}

.icon-play-circle:before {
  content: "\e936";
}

.icon-play-circle-outline:before {
  content: "\e91d";
}

.icon-play-list:before {
  content: "\e927";
}

.icon-play-list-filled:before {
  content: "\e90b";
}

.icon-plus:before {
  content: "\e95e";
}

.icon-plus-light:before {
  content: "\e92d";
}

.icon-plus-strong:before {
  content: "\ea0a";
}

.icon-printer:before {
  content: "\e98f";
}

.icon-professional:before {
  content: "\e998";
}

.icon-question-circle:before {
  content: "\e932";
}

.icon-questions:before {
  content: "\e958";
}

.icon-quiz:before {
  content: "\e96b";
}

.icon-rate:before {
  content: "\e91f";
}

.icon-reload:before {
  content: "\e9b2";
}

.icon-reload-light:before {
  content: "\e96a";
}

.icon-reply:before {
  content: "\e966";
}

.icon-rocket_sharp:before {
  content: "\e900";
}

.icon-save:before {
  content: "\e926";
}

.icon-screen_share:before {
  content: "\e901";
}

.icon-search:before {
  content: "\e9a6";
}

.icon-search-bold:before {
  content: "\e978";
}

.icon-search-light:before {
  content: "\e9b1";
}

.icon-select:before {
  content: "\e93c";
}

.icon-select-arrow:before {
  content: "\e948";
}

.icon-selection:before {
  content: "\e9b4";
}

.icon-send:before {
  content: "\e92a";
}

.icon-send-arrow:before {
  content: "\e967";
}

.icon-settings:before {
  content: "\e943";
}

.icon-shopping-cart:before {
  content: "\e97d";
}

.icon-signet:before {
  content: "\e980";
}

.icon-smile:before {
  content: "\e9e1";
}

.icon-smile2:before {
  content: "\e9e2";
}

.icon-subtitles:before {
  content: "\e95d";
}

.icon-take-part:before {
  content: "\e95c";
}

.icon-text:before {
  content: "\e968";
}

.icon-text-source:before {
  content: "\e960";
}

.icon-tick:before {
  content: "\e92b";
}

.icon-tick-bold:before {
  content: "\e981";
}

.icon-tick-outline:before {
  content: "\e95a";
}

.icon-title:before {
  content: "\e970";
}

.icon-twitter:before {
  content: "\e908";
}

.icon-twitter_x_outline:before {
  content: "\e99c";
}

.icon-underline:before {
  content: "\e933";
}

.icon-user:before {
  content: "\e957";
}

.icon-user-add:before {
  content: "\e977";
}

.icon-user-profile:before {
  content: "\e9af";
}

.icon-verified-filled:before {
  content: "\e9b9";
}

.icon-video-library:before {
  content: "\e929";
}

.icon-video-library-filled:before {
  content: "\e904";
}

.icon-video-source:before {
  content: "\e961";
}

.icon-view:before {
  content: "\e916";
}

.icon-visibility-filled:before {
  content: "\e9bd";
}

.icon-visibility-off-filled:before {
  content: "\e9bc";
}

.icon-visibility-off-outline:before {
  content: "\e9be";
}

.icon-visibility-outline:before {
  content: "\e9bf";
}

.icon-volume:before {
  content: "\e917";
}

.icon-volume-high:before {
  content: "\e91a";
}

.icon-volume-low:before {
  content: "\e93d";
}

.icon-volume-medium:before {
  content: "\e919";
}

.icon-whapp-outline:before {
  content: "\e973";
}

.icon-www:before {
  content: "\e909";
}

.icon-youtube:before {
  content: "\e90a";
}

.icon-youtube-play:before {
  content: "\e9cf";
}

.icon-zoom:before {
  content: "\e9d0";
}

.icon-zoom-in:before {
  content: "\e9d1";
}

.icon-zoom-out:before {
  content: "\e9d2";
}

/* You can add global styles to this file, and also import other style files */
html {
  font-size: 16px;
}

* {
  font-family: "Muli", sans-serif;
  -webkit-touch-callout: none;
  /* prevent callout to copy image, etc when tap to hold */
  -webkit-text-size-adjust: none;
  /* prevent webkit from resizing text to fit */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  /* prevent tap highlight color / shadow */
  -webkit-tap-highlight-color: transparent;
  /* prevent copy paste, to allow, change 'none' to 'text' */
}

body {
  margin: 0;
  padding: 0;
  height: fit-content;
  min-height: 100%;
  width: 100%;
  position: fixed;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
body::before, body::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}